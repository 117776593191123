import { getNumberAsCurrency } from '@helpers/miscelleanous';
import { OPPORTUNITY_BOND_TYPES, OPPORTUNITY_TAGS } from './constants';
import {
    OpportunityTag,
    OpportunityTagType,
} from '@helpers/enums/opportunity-enums';

export const menuItems = [
    {
        id: OPPORTUNITY_BOND_TYPES.BOND,
        label: 'Bonds',
        icon: '/images/opportunities/BondsIcon.svg',
    },
    {
        id: OPPORTUNITY_BOND_TYPES.SDIS,
        label: 'SDIs',
        icon: '/images/opportunities/SDIIcon.svg',
    },
    {
        id: OPPORTUNITY_BOND_TYPES.TreasuryBill,
        label: 'T-Bills',
        icon: '/images/opportunities/TbillsIcon.svg',
    },
];

export const regulationTabs = [
    {
        id: 'rbi',
        value: 'RBI Regulated',
    },
    {
        id: 'sebi',
        value: 'SEBI Regulated',
    },
];

export const getOpportunityFields = ({ irr, tenure, minInvestment }) => {
    return [
        {
            id: 'yield',
            label: 'Yield',
            value: irr,
        },
        {
            id: 'tenure',
            label: 'Tenure',
            value: tenure,
        },
        {
            id: 'minInvestment',
            label: 'Min Investment',
            value: minInvestment,
            className: 'hidden md:inline-flex',
        },
    ];
};

export const quickFilters = [
    {
        id: 'minInvestmentLessThan10k',
        label: 'Min Inv ≤ ₹10k',
        value: '0-10000',
        key: 'amount',
    },
    {
        id: 'yieldGreaterThan12',
        label: 'Yield ≥ 12%',
        value: '>12',
        key: 'yield',
    },
    {
        id: 'highRated',
        label: 'High Rated',
        value: 'high',
        key: 'rating',
    },
    {
        id: 'secured',
        label: 'Secured',
        value: 'true',
        key: 'secured',
    },
    {
        id: 'tenureLessThan1Y',
        label: 'Tenure < 1Y',
        value: '<1',
        key: 'tenure',
    },
];

const getRandomTag = () => {
    const tagsArray = Object.values(OPPORTUNITY_TAGS);
    const randomIndex = Math.floor(Math.random() * tagsArray.length);
    return tagsArray[randomIndex]; // Return the random tag
};

export const getOpportunityData = (opportunity, isBonds, isClosed) => {
    return {
        logo: opportunity.logo,
        title: opportunity?.title || '-',
        industryType: opportunity?.industryType || '-',
        irr: opportunity?.displayIRR ? `${opportunity.displayIRR}%` : '-',
        tenure: opportunity?.tenure || '-',
        minInvestment:
            opportunity.minInvestment >= 0
                ? getNumberAsCurrency(Math.round(opportunity.minInvestment))
                : '-',
        riskRating: opportunity?.displayRating || '-',
        tagType: opportunity?.tagType,
        tagLabel: opportunity?.tagLabel,
        slug: opportunity?.slug,
        id: opportunity?.id,
        preOfferIRR: opportunity?.opportunityPreFestiveOfferIRR
            ? `${opportunity.opportunityPreFestiveOfferIRR}%`
            : '-',
    };
};

export const getTagLabel = (type, tagLabel = '') => {
    switch (type) {
        case OpportunityTag.COMING_SOON:
            return OpportunityTagType.COMING_SOON;
        case OpportunityTag.FESTIVE_DEAL:
            return OpportunityTagType.FESTIVE_DEAL;
        default:
            return tagLabel || '';
    }
};

export const getTagStyles = (type) => {
    const tagStyles = {
        newlyAdded: {
            gradient: 'bg-newly-added',
            leftCurve: '#E4F6F5',
            rightCurve: '#A3E1DE',
        },
        highYield: {
            gradient: 'bg-high-yield',
            leftCurve: '#F2F2FC',
            rightCurve: '#CFCFF5',
        },
        lowMinInvestment: {
            gradient: 'bg-min-investment',
            leftCurve: '#FFF3EB',
            rightCurve: '#FFD6B8',
        },
        shortTenure: {
            gradient: 'bg-short-tenure',
            leftCurve: '#FFF0F1',
            rightCurve: '#FFC8CA',
        },
        comingSoon: {
            gradient: 'bg-coming-soon',
            leftCurve: '#00172F',
            rightCurve: '#002E5E',
            textColor: 'text-basicWhite',
        },
        festiveDeal: {
            gradient: 'bg-festive-deal',
            leftCurve: '#FFECBC',
            rightCurve: '#EBCE96',
            textColor: 'text-secondary-900',
        },
        default: {
            gradient: 'bg-curated',
            leftCurve: '#EBF6FF',
            rightCurve: '#C2E3FF',
        },
    };

    return tagStyles[type] || tagStyles.default;
};

export const getTitleAndDescription = (
    isActiveOpportunities,
    hasFilters,
    isListedSDI,
    type
) => {
    let title = '';
    let description = '';
    if (hasFilters) {
        title = 'No results found matching your search';
        description = 'Please refine your filter criteria.';
    } else if (!isActiveOpportunities) {
        title = 'No opportunities found';
        description =
            'Kindly explore active opportunities to meet your investment goals.';
    } else {
        switch (type) {
            case OPPORTUNITY_BOND_TYPES.BOND:
                title = 'No Bonds available to invest';
                description =
                    'We are working on bringing in more Bond opportunities. In the meantime, kindly explore other opportunities on Jiraaf.';
                break;
            case OPPORTUNITY_BOND_TYPES.TreasuryBill:
                title = 'No T-Bills available to invest';
                description =
                    'We are working on bringing in more T-Bill opportunities. In the meantime, kindly explore other opportunities on Jiraaf.';
                break;
            case OPPORTUNITY_BOND_TYPES.SDIS:
                title = isListedSDI
                    ? 'No SEBI regulated SDIs available to invest'
                    : 'No RBI regulated SDIs available to invest';

                description = isListedSDI
                    ? 'We are working on bringing in more SEBI regulated SDI opportunities. In the meantime, kindly explore other opportunities on Jiraaf.'
                    : 'We are working on bringing in more RBI regulated SDI opportunities. In the meantime, kindly explore other opportunities on Jiraaf.';
                break;
            default:
                title = 'No opportunities found';
                description =
                    'Kindly explore active opportunities to meet your investment goals.';
                break;
        }
    }

    return { title, description };
};

export const getScrollThreshold = (isAuthorized, windowWidth) => {
    return isAuthorized
        ? '360px'
        : windowWidth <= 768
        ? '2000px'
        : windowWidth > 768 && windowWidth < 1024
        ? '1500px'
        : '1000px';
};
