import { cn } from '@ui/atoms/utils/utils';
import { buttonSizes, buttonTypes } from '@ui/atoms/utils/enums';
import Button from '@ui/atoms/design-system/Button';
import { Image, Text } from '@ui/atoms';
import texts from '@components/Homepage/OpportunitiesCarousel/en.json';
import { OpportunityLogo } from '@molecules/Opportunity';
import { getOpportunityFields } from '@components/OpportunityListing/utils/helper';
import PopularTag from '@components/OpportunityListing/components/OpporunityCard/PopularTag';
import { OpportunityCarouselTags } from '@helpers/enums/opportunity-enums';

const OpportunityCard = ({
    details,
    isActive,
    handleOnClick,
    index,
    width,
    showCta,
    onClickCta,
}) => {
    const {
        title,
        image,
        description,
        yield: irr,
        tenure,
        minInvestment,
        ratingCategory,
        status,
        tag,
    } = details;

    const fields = getOpportunityFields({
        irr,
        tenure,
        minInvestment,
    });

    return (
        <div
            className={cn(
                'opportunities_carousel__slide h-max -z-1 w-full max-w-[328px] min-w-[328px] md:min-w-[350px] md:max-w-[350px]',
                {
                    'min-h-max h-full z-1': isActive,
                    'first:ml-16 last:ml-32': width < 768,
                    'md:min-w-[358px] md:max-w-[358px] min-w-[328px] max-w-[328px]':
                        showCta,
                }
            )}
            key={index}
        >
            <div
                className="flex flex-col items-center h-full overflow-hidden opportunities_carousel__slide__number rounded-xl"
                id={index}
            >
                <div className="relative flex flex-col w-full gap-4 overflow-hidden border bg-basicWhite border-primary-100 rounded-xl pt-9 bg-opportunity-card-bg">
                    <PopularTag
                        type={tag?.name || ''}
                        className={cn(
                            'absolute top-0 z-10 left-1/2 -translate-x-1/2',
                            {
                                '': !isActive,
                            }
                        )}
                        tagLabel={tag?.displayName || ''}
                    />
                    <div className="relative w-full h-full">
                        <Image
                            src={`/images/opportunities/OppCardBg.svg`}
                            className="absolute bottom-0 left-0 right-0 hidden object-cover w-full h-full top- md:inline-block"
                        />
                        <Image
                            src={`/images/opportunities/OppCardBgMobile.svg`}
                            className="absolute top-0 left-0 right-0 inline-block object-cover w-full h-full bottom-1 md:hidden"
                        />
                        <div className="relative z-10 flex flex-col justify-center gap-4 px-4 pb-4">
                            <div
                                className={cn(
                                    'flex gap-3 pt-0 md:items-start md:pt-0'
                                )}
                            >
                                <div className="w-10 h-10 md:w-[60px] md:h-[60px]">
                                    <OpportunityLogo
                                        alt={title}
                                        logo={image}
                                        wrapperClass="w-10 h-10 md:w-[60px] md:h-[60px]"
                                        className={
                                            'object-contain w-10 h-10 md:w-[60px] md:h-[60px]'
                                        }
                                    />
                                </div>
                                <div className="flex flex-col items-start gap-2">
                                    <div className="flex flex-col gap-1">
                                        <div
                                            title={title}
                                            className={cn(
                                                'p4-semibold text-gray-900 line-clamp-1'
                                            )}
                                        >
                                            {title}
                                        </div>
                                        <Text
                                            content={description}
                                            className={cn(
                                                'text-gray-900 p7-regular'
                                            )}
                                        />
                                    </div>
                                    <div
                                        className={cn(
                                            'text-center p7-semibold py-0.5 px-1 rounded border-[0.5px]',
                                            {
                                                '  bg-semantic-success-light  border-semantic-success-base':
                                                    status ===
                                                    OpportunityCarouselTags.LIVE_NOW,
                                                ' bg-semantic-info-light border-semantic-info-base':
                                                    status ===
                                                    OpportunityCarouselTags.FULLY_SUBSCRIBED,
                                            }
                                        )}
                                    >
                                        <Text
                                            content={status}
                                            className={cn(
                                                'text-center p7-semibold',
                                                {
                                                    'text-semantic-success-base':
                                                        status ===
                                                        OpportunityCarouselTags.LIVE_NOW,
                                                    'text-semantic-info-base ':
                                                        status ===
                                                        OpportunityCarouselTags.FULLY_SUBSCRIBED,
                                                }
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row items-end w-full min-h-0 gap-10 md:gap-12">
                                {fields.map(
                                    ({ id, label, value, className }) => (
                                        <div
                                            className={cn(
                                                'flex flex-col items-start '
                                            )}
                                            key={id}
                                        >
                                            <Text
                                                content={value}
                                                className={cn(
                                                    'text-primary-500',
                                                    {
                                                        'p1-bold':
                                                            id === 'yield',
                                                        'p4-bold':
                                                            id !== 'yield',
                                                    }
                                                )}
                                            />
                                            <Text
                                                content={label}
                                                className="text-gray-500 p7-regular whitespace-nowrap"
                                            />
                                        </div>
                                    )
                                )}
                            </div>
                            {showCta && isActive && (
                                <Button
                                    buttonText={
                                        details?.canInvest
                                            ? texts?.InvestNow
                                            : texts?.ViewDetails
                                    }
                                    buttonType={buttonTypes.SECONDARY}
                                    buttonSize={buttonSizes.EXTRA_SMALL}
                                    widthClass={'w-full !p5-medium'}
                                    onClick={() => handleOnClick(details)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className={cn('h-full place-content-center')}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                        >
                            <path
                                d="M28 0H0L13.4895 20.2342C16.7239 25.0858 22.1691 28 28 28V0Z"
                                fill="#F4F6F8"
                            />
                            <mask
                                id="mask0_36799_104455"
                                style={{ maskType: 'alpha' }}
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="28"
                                height="28"
                            >
                                <path
                                    d="M28 0H9.53674e-07L13.4895 20.2342C16.7239 25.0858 22.1691 28 28 28V28V0Z"
                                    fill="#FFF9EF"
                                />
                            </mask>
                            <g mask="url(#mask0_36799_104455)">
                                <path
                                    d="M9.53674e-07 0L13.4895 20.2342C16.7239 25.0858 22.1691 28 28 28V28"
                                    stroke="#93B1CA"
                                    stroke-width="2"
                                />
                            </g>
                        </svg>
                    </div>
                    <div
                        className={cn(
                            'relative  py-1.5 px-1 md:px-3 w-[232px]  border-primary-100 border-b-1 border-l-0 border-r-0 rounded-b-none flex items-center gap-2 h-7 bg-basicWhite bg-opp-info-strip text-center mx-auto'
                        )}
                    >
                        <Text
                            content={ratingCategory}
                            className={cn(
                                'p7-regular md:p5-medium text-primary-500 text-center w-full line-clamp-1'
                            )}
                        />
                    </div>
                    <div className={cn('h-full place-content-center')}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                        >
                            <g clip-path="url(#clip0_36799_104460)">
                                <path
                                    d="M0 0H28L14.5105 20.2342C11.2761 25.0858 5.83095 28 0 28V0Z"
                                    fill="white"
                                />
                                <mask
                                    id="mask0_36799_104460"
                                    style={{ maskType: 'alpha' }}
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="28"
                                    height="28"
                                >
                                    <path
                                        d="M0 0H28L14.5105 20.2342C11.2761 25.0858 5.83095 28 0 28V28V0Z"
                                        fill="#FFF9EF"
                                    />
                                </mask>
                                <g mask="url(#mask0_36799_104460)">
                                    <path
                                        d="M28 0L14.5105 20.2342C11.2761 25.0858 5.83095 28 0 28V28"
                                        stroke="#93B1CA"
                                        stroke-width="2"
                                    />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_36799_104460">
                                    <rect width="28" height="28" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpportunityCard;
