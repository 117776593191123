import {
    getTagLabel,
    getTagStyles,
} from '@components/OpportunityListing/utils/helper';
import { Text } from '@ui/atoms';
import { cn } from '@ui/atoms/utils/utils';
import React from 'react';

const PopularTag = ({ type, className, tagLabel = '' }) => {
    const style = getTagStyles(type);
    const label = getTagLabel(type, tagLabel);
    return (
        label && (
            <div
                className={cn(
                    `flex items-center justify-center w-fit px-3 py-1 rounded-b-lg relative shadow-lg`,
                    {
                        [className]: className,
                        [style?.gradient]: style?.gradient,
                    }
                )}
            >
                <Text
                    content={label}
                    className={cn('text-gray-900 p7-semibold', style.textColor)}
                />
                <b className={cn('absolute top-0 w-2 h-full -left-2')}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                    >
                        <path
                            d="M7.99982 8C7.99982 3.58172 4.41818 0 0 0H8L7.99982 8Z"
                            fill={style?.leftCurve}
                        />
                    </svg>
                </b>
                <b
                    className={cn(
                        'absolute top-0 w-2 h-full -right-2 scale-x-[-1]'
                    )}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                    >
                        <path
                            d="M7.99982 8C7.99982 3.58172 4.41818 0 0 0H8L7.99982 8Z"
                            fill={style?.rightCurve}
                        />
                    </svg>
                </b>
            </div>
        )
    );
};

export default PopularTag;
