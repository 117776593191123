import { Image } from '@ui/atoms';
import { cn } from '@ui/atoms/utils/utils';
import React from 'react';

const OpportunityLogo = ({ logo, alt, wrapperClass, className, ...props }) => {
    return (
        <div
            className={cn(
                'w-17 h-17 px-0.5 border border-gray-300 rounded-lg xl:px-1.5 bg-basicWhite',
                wrapperClass
            )}
        >
            <Image
                src={logo}
                className={cn('object-scale-down w-full h-full', className)}
                alt={alt}
                {...props}
            />
        </div>
    );
};

export default OpportunityLogo;
