import { useEffect, useCallback, useState, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useWindowSize } from '@uidotdev/usehooks';
import {
    OPPORTUNITY_CAROUSEL_DESKTOP_MARGIN,
    OPPORTUNITY_CAROUSEL_MOBILE_MARGIN,
    TWEEN_FACTOR_BASE,
} from '@components/Homepage/OpportunitiesCarousel/utils/constants';
import { numberWithinRange } from '@components/Homepage/OpportunitiesCarousel/utils/helpers';

const useOpportunitiesCarousel = ({ opportunities, scaleFactor, showCta }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const tweenFactor = useRef(0);
    const tweenNodes = useRef([]);
    const { width } = useWindowSize();
    
    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            loop: true,
            align: 'center',
        },
    );

    const scrollPrev = useCallback(() => {
        emblaApi?.scrollPrev();
    }, [emblaApi]);

    const scrollNext = useCallback(() => {
        emblaApi?.scrollNext();
    }, [emblaApi]);

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setActiveSlide(emblaApi.selectedScrollSnap());
    }, [emblaApi]);

    const setTweenNodes = useCallback((emblaApi) => {
        tweenNodes.current = emblaApi.slideNodes().map(slideNode =>
            slideNode.querySelector('.opportunities_carousel__slide__number')
        );
    }, []);

    const setTweenFactor = useCallback((emblaApi) => {
        tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length;
    }, []);

    const calculateDiffToTarget = (scrollSnap, scrollProgress, engine, slideIndex) => {
        let diffToTarget = scrollSnap - scrollProgress;

        if (engine.options.loop) {
            engine.slideLooper.loopPoints.forEach(loopItem => {
                const target = loopItem.target();
                const sign = Math.sign(target);

                if (slideIndex === loopItem.index && target !== 0) {
                    diffToTarget = sign === -1 ? scrollSnap - (1 + scrollProgress) : scrollSnap + (1 - scrollProgress);
                }
            });
        }

        return diffToTarget;
    };

    const applyTransformations = (
        tweenNode,
        diffToTarget,
        snapIndex,
        emblaApi,
        opportunities
    ) => {
        const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current);
        const scale = numberWithinRange(tweenValue, scaleFactor, 1).toString();

        const isMobile = width < 1280;
        const isTabletOrLarger = width >= 768;
        const isSelectedSnap = emblaApi.selectedScrollSnap() === snapIndex;

        // Determine margin factor based on device width and CTA visibility
        const marginFactor = isMobile
            ? showCta
                ? -180
                : -190
            : showCta
            ? -138
            : -216;

        // Compute transformation value
        let transformValue = `scale(${scale})`;

        if (isTabletOrLarger) {
            const translateX = isSelectedSnap
                ? 0
                : diffToTarget > 0
                ? marginFactor
                : -marginFactor;

            transformValue += ` translateX(${translateX}px)`;
        }

        // Apply transformations
        tweenNode.style.transform = transformValue;

        // Set z-index dynamically
        tweenNode.parentNode.style.zIndex = Math.round(
            isSelectedSnap ? opportunities.length : diffToTarget * -1
        );

        // Remove box shadow for wider screens
        if (isTabletOrLarger) {
            tweenNode.style.boxShadow = 'none';
        }
    };

    const tweenScale = useCallback(
        (emblaApi, eventName) => {
            const engine = emblaApi.internalEngine();
            const scrollProgress = emblaApi.scrollProgress();
            const isScrollEvent = eventName === 'scroll';
            const slidesInView = emblaApi.slidesInView(true);

            emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
                const slidesInSnap = engine.slideRegistry[snapIndex];

                slidesInSnap.forEach(slideIndex => {
                    if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

                    const diffToTarget = calculateDiffToTarget(scrollSnap, scrollProgress, engine, slideIndex);
                    const tweenNode = tweenNodes.current[slideIndex];

                    applyTransformations(tweenNode, diffToTarget, snapIndex, emblaApi, opportunities);
                });
            });
        },
        [width]
    );

    useEffect(() => {
        if (!emblaApi) return;

        setTweenNodes(emblaApi);
        setTweenFactor(emblaApi);
        tweenScale(emblaApi);

        emblaApi
            .on('select', onSelect)
            .on('reInit', setTweenNodes)
            .on('reInit', setTweenFactor)
            .on('reInit', tweenScale)
            .on('scroll', tweenScale)
            .on('slideFocus', tweenScale);
    }, [emblaApi, tweenScale]);

    return {
        activeSlide,
        emblaRef,
        scrollPrev,
        scrollNext,
    };
};

export default useOpportunitiesCarousel;